<template>
  <section class="InAppNetworksContent">
    <div class="InAppNetworksContent-Header">
      <img :src="require(`@/assets/images/${switchBgImage}`)" alt="bg" class="InAppNetworksContent-Bg">
      <span class="InAppNetworksContent-Subtitle">
       MONTERIZO
      </span>
      <h1 class="InAppNetworksContent-Title">
        In-app
        <span>
          Network
        </span>
      </h1>
    </div>
    <InAppNetwork/>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import InAppNetwork from "@/components/InAppNetwork";

export default {
  name: "InAppNetworkContent",
  components: {InAppNetwork},
  computed: {
    ...mapGetters({
      isMobile: 'isMobile'
    }),
    switchBgImage() {
      return this.isMobile ? 'inapp-bg.png' : 'inapp-bg.png'
    }
  }
}
</script>

<style lang="scss">
.InAppNetworksContent {
  width: 100%;
  max-width: 100%;

  &-Bg {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &-Header {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 1;
    height: 44.271vw;
  }

  &-Title {
    position: absolute;
    top: 41%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    font-weight: 900;
    font-size: 11.042vw;
    letter-spacing: 0.02em;
    line-height: .97;
    text-align: right;
    text-transform: uppercase;
    color: var(--color-text-main1);

    .mobile &,
    .landscape &,
    .portrait & {
      //left: 50%;
      //font-size: 15.7vw;
    }

    > span {
      font-size: 11.93vw;
      color: var(--color-text-main);
    }
  }

  &-Subtitle {
    position: absolute;
    z-index: 3;
    top: 29%;
    left: 17.6%;
    font-size: 3.34vw;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.02em;
    color: var(--color-text-main);

    .mobile &,
    .landscape &,
    .portrait & {
      //font-size: 9.6vw;
    }
  }

  &-InAppNetworks {
    margin-top: -11vw;

    .mobile &,
    .landscape &,
    .portrait & {
      margin-top: -15vw;
    }
  }
}
</style>