<template>
  <div class="InAppNetwork">
    <img src="@/assets/images/inapp-bg2.png" alt="">
    <div class="Wrapper">
      <div class="InAppNetwork-Content">
        <img src="@/assets/images/inapp-logos.png" alt="">
        <img src="@/assets/images/about-in-app.svg" alt="" class="Img">
        <div class="InAppNetwork-Info">
          <p>
            The MONTERIZO Network works with the largest advertising platforms and publishers. We've generated over
            1
            billion impressions and attracted over 2 million active users
          </p>
          <span>
        We filter traffic using a modern anti-fraud system: <strong>Protect 360</strong>
      </span>
          <ul>
            <li
                v-for="(text, i) in infos"
                :key="i"
            >
              <img src="@/assets/images/arrow-up.svg" alt="">
              {{ text }}
            </li>
          </ul>
        </div>
        <div class="InAppNetwork-Slider">
          <swiper :options="options">
            <swiper-slide
                v-for="(img, k) in slides"
                :key="k"
            >
              <img :src="require(`@/assets/images/${img}`)" alt="">
            </swiper-slide>
          </swiper>
          <img src="@/assets/images/arrow-left-white.svg" alt="Left" class="Left" slot="button-prev">
          <img src="@/assets/images/arrow-right-white.svg" alt="Right" class="Right" slot="button-next">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {Navigation} from "swiper";

export default {
  name: "InAppNetwork",
  data() {
    return {
      options: {
        modules: [Navigation],
        slidesPerView: 1,
        speed: 700,
        centeredSlides: true,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        loop: true,
        navigation: {
          nextEl: '.Right',
          prevEl: '.Left'
        }
      },
      infos: [
        "Indicators: CTIT, CR, uninstall rates and others",
        "Special traffic distribution algorithm - DSP",
        "High LTV of our traffic",
      ],
      slides: [
        "phone1.png",
        "phone2.png",
        "phone3.png",
      ]
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile'
    })
  }
}
</script>

<style lang="scss">
.InAppNetwork {
  position: relative;
  padding: 0 0 4vw;
  margin-top: 2vw;

  > img {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &-Content {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    > img {
      position: absolute;
      top: -17%;
      left: 50%;
      width: 63.638%;
      transform: translate(-50%, 0);
    }

    .Img {
      top: -33%;
      width: 55.86%;
    }
  }

  &-Info {
    flex: 0 0 41%;
    padding-bottom: 6vw;

    > p {
      margin-bottom: 1.563vw;
      font-weight: 400;
      font-size: 1.563vw;
      line-height: 1.3;
      color: var(--color-text-main);
    }

    > span {
      display: block;
      margin-bottom: 1.05vw;
      font-weight: 400;
      font-size: 1.563vw;
      line-height: 1.5;
      color: #E3CBFF;

      > strong {
        color: #51B56D;
      }
    }

    > ul {
      li {
        display: inline-flex;
        justify-content: start;
        align-items: center;
        padding: .73vw 1.771vw;
        font-weight: 700;
        font-size: 1.25vw;
        line-height: 150%;
        color: #FFFFFF;
        background: #51B56D;
        border-radius: 100px;

        &:not(&:last-child) {
          margin-bottom: 0.26vw;
        }

        > img {
          width: 2.29vw;
          margin-right: 1.042vw;
        }
      }
    }
  }

  &-Slider {
    position: relative;
    flex: 0 0 41%;
    overflow-x: hidden;

    .Right,
    .Left {
      position: absolute;
      top: 49%;
      z-index: 5;
      width: 1vw;
      transform: translate(0, -50%);
      cursor: pointer;
    }

    .Right {
      right: 0;
    }
  }

  .swiper-slide {
    img {
      display: block;
      width: 29.5vw;
      margin: 0 auto;
    }
  }
}
</style>