<template>
  <div class="InAppNetworkPage">
    <InAppNetworkContent />
  </div>
</template>

<script>
import InAppNetworkContent from "@/components/InAppNetworkContent";
export default {
  name: "InAppNetworkPage",
  components: {
    InAppNetworkContent
  },
  metaInfo: {
    title: 'In-app Network - Monterizo',
   /* meta: [{
      vmid: 'description',
      name: 'description',
      content:"Here You Can Find Our Cases and Study Them ✅ 8+ Years Expertise ✅ 300+ Professional Who Work for Result ✅ More Than 500 000 FTD Per Year",
    }],*/
  }
}
</script>

<style lang="scss">
.InAppNetworkPage {
  padding: 3.9vw 0 0;
  background-color: var(--color-body1);

  .mobile &,
  .landscape &,
  .portrait & {
    padding: 13.2vw 0 0;
  }
}
</style>